import React, { useState } from 'react';
import './App.css';
import axios from 'axios';

const App = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');

  const handleSendMessage = async () => {
    if (input.trim()) {
      // Replace the user's message with the latest one
      setMessages([{ sender: 'user', text: input }]);

      try {
        // Make a POST request to your server
        const response = await axios.post('https://frozen-wave-57486-08ae7d2b8b49.herokuapp.com/api/chat', {
          message: input
        });

        // Add Hailey's new response
        setMessages((prevMessages) => [
          ...prevMessages.filter(msg => msg.sender !== 'hailey'), // Remove old Hailey's message
          { sender: 'hailey', text: response.data.response } // Add new Hailey message
        ]);
      } catch (error) {
        console.error('Error fetching AI response:', error);
      }

      // Clear the input field after sending
      setInput('');
    }
  };

  return (
    <div className="chat-container">
      {messages.map((msg, index) => (
        <div key={index} className={`message ${msg.sender}`}>
          <div className="bubble">{msg.text}</div>
        </div>
      ))}

      <div className="chat-input">
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
          placeholder="Type your message..."
        />
        <button onClick={handleSendMessage}>Send</button>
      </div>
      <footer className="custom-footer">
        <nav>
          <ul className="footer-icons">
            <li>
              <a href="https://pump.fun" target="_blank" rel="noopener noreferrer">
                <img src="pumpfun.svg" alt="dexscreener" />
              </a>
            </li>
            <li>
              <a href="https://x.com/TalkTuahAI" target="_blank" rel="noopener noreferrer">
                <img src="x.svg" alt="x" />
              </a>
            </li>
            <li>
              <a href="http://t.me/TalkTuahAI" target="_blank" rel="noopener noreferrer">
                <img src="telegram.svg" alt="telegram" />
              </a>
            </li>
          </ul>
        </nav>
      </footer>
    </div>
  );
};

export default App;
